/* eslint-disable max-len */
import { useMemo, FC } from 'react';
import { useIntl } from 'react-intl';
import Link from 'next/link';
import { Box, Flex } from '@chakra-ui/react';
import {
  Button,
  AppCell,
  Header,
  Logo,
  Heading,
  EmoFace,
} from '@emochan-cabinet/ui';
import { useAuthContext } from '~/hooks/useAuth';

type ErrorLayoutProps = {
  statusCode: number
  href?: string
  shouldForceSignOut?: boolean
}

const ErrorLayout: FC<ErrorLayoutProps> = ({ statusCode, href = '/', shouldForceSignOut = false }) => {
  const { formatMessage } = useIntl();
  const { signout } = useAuthContext();

  const linkLogo = useMemo(() => (
    <Link href={href}>
      <Logo kind="emochanFull" size="xxs" />
    </Link>
  ), [href]);

  const message = useMemo(() => {
    if (statusCode === 404) {
      return formatMessage({ id: 'error.message.notfound' });
    }

    if (statusCode === 403) {
      return formatMessage({ id: 'error.message.forbidden' });
    }

    return formatMessage({ id: 'error.message.common' });
  }, [statusCode, formatMessage]);

  return (
    <AppCell as="main" h="100%" flexDirection="column">
      <AppCell.Item flexGrow={0}>
        <Header
          start={{ logo: (linkLogo), label: 'emochan' }}
        />
      </AppCell.Item>
      <Flex color="grey.60" px="16" flexGrow={1} justifyContent="center" alignItems="center" flexDirection="column" bg="#e5e5e5">
        <Box w="304px">
          <svg
            viewBox="0 0 288 144"
            fill="currentColor"
          >
            {
              [
                'M45.0949 79.0106L43.4844 76.1685C45.1896 74.6527 46.6107 73.6106 48.6002 72.5685L50.3054 75.6948C48.316 76.5475 46.7054 77.6843 45.0949 79.0106ZM47.6528 82.8001L45.9475 79.958C47.5581 78.5369 49.0739 77.4948 51.0633 76.6422L52.8633 79.6738C51.0633 80.4317 49.2633 81.6633 47.6528 82.8001ZM52.2002 84.6948C53.5265 82.6106 54.8528 79.8632 54.8528 77.3053C54.7581 74.3685 52.9581 71.3369 51.2528 69.158L55.0423 67.9264C57.5054 71.3369 59.2107 75.3159 58.5475 79.579C58.1686 82.0422 57.1265 84.3159 55.9896 86.4948L52.2002 84.6948Z',
                'M69.8203 54.9474C70.8624 52.6737 70.9572 50.4948 70.4835 48.0316L79.7677 41.4C82.4203 45.6632 83.9361 50.4 82.4203 55.3264C81.6624 57.979 79.9572 60.2527 78.0624 62.2421L74.8414 60.3474C78.7256 56.5579 80.8098 51.8211 78.2519 46.6106L74.3677 49.3579C74.4624 51.6316 74.1782 53.6211 73.3256 55.7053L69.8203 54.9474Z',
                'M202.073 51.3474C204.252 52.6737 206.905 53.9053 209.463 53.9053C212.4 53.8105 215.431 51.9158 217.61 50.2105L218.842 54C215.431 56.4632 211.547 58.2632 207.189 57.6948C204.726 57.3158 202.452 56.3684 200.273 55.1369L202.073 51.3474ZM203.873 46.8947L206.621 45.1895C208.042 46.8 209.084 48.3158 210.031 50.2105L207 52.0105C206.337 50.2105 205.01 48.4105 203.873 46.8947ZM207.663 44.1474L210.505 42.5369C212.021 44.2421 213.063 45.5684 214.2 47.5579L211.168 49.2632C210.221 47.4632 208.989 45.8526 207.663 44.1474Z',
                'M232.106 68.5895C234.38 69.6316 236.559 69.6316 239.022 69.1579L245.843 78.3474C241.675 81.0947 236.938 82.6105 231.917 81.1895C229.264 80.4316 226.896 78.821 224.906 76.9263L226.801 73.7053C230.59 77.4947 235.422 79.5789 240.538 76.9263L237.696 73.1368C235.422 73.2316 233.433 73.0421 231.348 72.1895L232.106 68.5895Z',
              ].map((path) => <path key={path} d={path} />)
            }
          </svg>
        </Box>
        <Box w="120px" pb="32" marginTop="-60px">
          <EmoFace feeling="sadness" />
        </Box>
        <Heading size="small" align="center">
          {message}
        </Heading>
        <Box py="48">
          <Link passHref href={href} legacyBehavior>
            <Button as="a" shape="pill" buttonStyle="outline" color="primary" label={formatMessage({ id: 'error.backToTop' })} onClick={shouldForceSignOut ? signout : undefined} />
          </Link>
        </Box>
      </Flex>
    </AppCell>
  );
};

export default ErrorLayout;
