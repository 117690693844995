import type { ReactNode, FC } from 'react';
import Head from 'next/head';
import { useIntl } from 'react-intl';
import { useAuthContext } from '~/hooks/useAuth';
import FullPageSpinner from '~/components/ui/FullPageSpinner';
import LoginView from '~/components/Login';

type AuthSkinProps = {
  children?: ReactNode
}

const AuthSkin: FC<AuthSkinProps> = ({ children }) => {
  const { currentUser } = useAuthContext();
  const { formatMessage } = useIntl();

  if (currentUser.requested === false) {
    return <FullPageSpinner />;
  }

  if (currentUser.uid === '') {
    return <LoginView />;
  }

  return (
    <>
      <Head>
        <meta name="description" content={formatMessage({ id: 'app.description.member' })} />
        <meta property="og:description" content={formatMessage({ id: 'app.description.member' })} />
      </Head>
      {children}
    </>
  );
};

AuthSkin.displayName = 'AuthSkin';

export default AuthSkin;
