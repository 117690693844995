import { FC, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import Link from 'next/link';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Box, Flex, Container } from '@chakra-ui/react';
import {
  GoogleIcon,
  CrossCircleFilledIcon,
  VisibilityOffFilledIcon,
  VisibilityOnFilledIcon,
} from '@emochan-cabinet/icons';
import {
  AppCell,
  RainbowBar,
  Paragraph,
  Logo,
  Button,
  Input,
  FormControl,
  FormLabel,
  TextFieldRight,
  TextFieldGroup,
  FormHelper,
  IconButton,
} from '@emochan-cabinet/ui';
import { useAuthContext } from '~/hooks/useAuth';
import OrDivider from '~/components/ui/OrDivider';
import { VALID_PASSWORD } from '~/domain/rules/password';
import { translateAuthError } from '~/helpers/firebase';
import HelpLinks from '~/components/HelpLinks';

const LoginView: FC = () => {
  const { formatMessage } = useIntl();
  const { signup, loginWithEmailAndPassword } = useAuthContext();

  const emailTimer = useRef<NodeJS.Timeout | null>(null);
  const [showEmailButtons, setShowEmailButtons] = useState(false);
  const passwordTimer = useRef<NodeJS.Timeout | null>(null);
  const [showPasswordButtons, setShowPasswordButtons] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const loginSchemaZ = z
    .object({
      email: z
        .string()
        .email({ message: formatMessage({ id: 'auth.errors.email.invalid' }) }),
      password: z
        .string()
        .min(1, { message: formatMessage({ id: 'auth.errors.password.empty' }) })
        .regex(VALID_PASSWORD, { message: formatMessage({ id: 'auth.errors.password.invalid' }) }),
    });

  type FormData = z.infer<typeof loginSchemaZ>;

  const {
    handleSubmit,
    register,
    setError,
    setValue,
    setFocus,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({ resolver: zodResolver(loginSchemaZ) });

  function onSubmit(values: FormData) {
    loginWithEmailAndPassword(values.email, values.password)
      .then(() => {
        // ...
        // nothing
      })
      .catch((error) => {
        setError('email', { type: 'custom', message: translateAuthError(error, 'signin') });
        setError('password', { type: 'custom', message: translateAuthError(error, 'signin') });
      });
  }

  return (
    <AppCell as="main" flexDirection="column">
      <AppCell.Item flexGrow={0}>
        <RainbowBar />
      </AppCell.Item>
      <Flex flexGrow={1} py="48" flexDirection="column">
        <Container w="100%" maxW="container.small" centerContent>
          <Box>
            <Logo kind="emochanFull" size="medium" />
          </Box>

          <Box w="100%" pt="48" as="form" onSubmit={handleSubmit(onSubmit)}>
            <FormControl
              id="signin-email"
              isInvalid={!!errors.email}
            >
              <FormLabel text={formatMessage({ id: 'auth.email.label' })} />
              <TextFieldGroup>
                <Input
                  placeholder={formatMessage({ id: 'auth.email.placeholder' })}
                  {...register('email')}
                  type="email"
                  name="email"
                  required
                  autoComplete="current-email"
                  onFocus={() => {
                    if (emailTimer.current) {
                      clearTimeout(emailTimer.current);
                    }
                    setShowEmailButtons(true);
                  }}
                  onBlur={(e) => {
                    emailTimer.current = setTimeout(() => {
                      if (e.target.value.length === 0) {
                        setShowEmailButtons(false);
                      }
                    });
                  }}
                />
                <TextFieldRight visibility={showEmailButtons ? 'visible' : 'hidden'}>
                  <IconButton
                    type="button"
                    icon={<CrossCircleFilledIcon />}
                    buttonStyle="ghost"
                    label="clear"
                    onClick={() => {
                      setValue('email', '');
                      setFocus('email');
                    }}
                  />
                </TextFieldRight>
              </TextFieldGroup>
              {errors.email && errors.email.message && <FormHelper text={errors.email.message} />}
            </FormControl>

            <Box mt="16">
              <FormControl
                id="signin-password"
                isInvalid={!!errors.password}
              >
                <FormLabel text={formatMessage({ id: 'auth.password.label' })} />
                <TextFieldGroup>
                  <Input
                    placeholder={formatMessage({ id: 'auth.password.placeholder' })}
                    {...register('password')}
                    type={showPassword ? 'text' : 'password'}
                    required
                    autoComplete="current-password"
                    onFocus={() => {
                      if (passwordTimer.current) {
                        clearTimeout(passwordTimer.current);
                      }
                      setShowPasswordButtons(true);
                    }}
                    onBlur={(e) => {
                      passwordTimer.current = setTimeout(() => {
                        if (e.target.value.length === 0) {
                          setShowPasswordButtons(false);
                        }
                      });
                    }}
                  />
                  <TextFieldRight w="auto" visibility={showPasswordButtons ? 'visible' : 'hidden'}>
                    <IconButton
                      key="clear"
                      type="button"
                      icon={<CrossCircleFilledIcon />}
                      buttonStyle="ghost"
                      label="clear"
                      onClick={() => {
                        setValue('password', '');
                        setFocus('password');
                      }}
                    />
                    <IconButton
                      key="showpassword"
                      type="button"
                      icon={showPassword
                        ? <VisibilityOffFilledIcon />
                        : <VisibilityOnFilledIcon />}
                      buttonStyle="ghost"
                      label="show password"
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    />
                  </TextFieldRight>
                </TextFieldGroup>
                {errors.password && errors.password.message && (
                  <FormHelper text={errors.password.message} />
                )}
              </FormControl>
            </Box>

            <Box mt="24" mb="8" w="100%">
              <Button
                color="accent"
                label={formatMessage({ id: 'auth.button.label.emailLogin' })}
                full
                isLoading={isSubmitting}
                type="submit"
              />
            </Box>
          </Box>
          {/* as="form" */}

          <Box my="8" w="100%">
            <OrDivider>
              <Paragraph as="span" size="xs" align="center" color="grey.60">
                {formatMessage({ id: 'auth.login.methods.or' })}
              </Paragraph>
            </OrDivider>
          </Box>
          <Box mt="8" w="100%">
            <Button
              color="accent"
              buttonStyle="outline"
              leftIcon={<GoogleIcon />}
              onClick={signup}
              label={formatMessage({ id: 'auth.button.label.googleLogin' })}
              full
            />
          </Box>

          <Box py="16">
            <Link passHref href="/accounts/forgot_password" legacyBehavior>
              <Button
                as="a"
                buttonStyle="ghost"
                color="accent"
                size="xs"
                label={formatMessage({ id: 'auth.password.remind.link.label' })}
              />
            </Link>
          </Box>

          <HelpLinks as="footer" />

        </Container>
      </Flex>
    </AppCell>
  );
};

export default LoginView;
