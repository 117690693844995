import { FC, memo } from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';
import { Button } from '@emochan-cabinet/ui';
import { useIntl } from 'react-intl';

const HelpLinks: FC<FlexProps> = (props) => {
  const { formatMessage } = useIntl();

  return (
    <Flex justifyContent="center" alignItems="center" wrap="wrap" gap="16" {...props}>
      <Button
        size="xs"
        color="primary"
        buttonStyle="ghost"
        as="a"
        href={formatMessage({ id: 'navigation.terms.url' })}
        target="_blank"
        rel="noopener noreferrer"
        label={formatMessage({ id: 'navigation.terms' })}
      />
      <Button
        size="xs"
        color="primary"
        buttonStyle="ghost"
        as="a"
        href={formatMessage({ id: 'navigation.privacypolicy.url' })}
        target="_blank"
        rel="noopener noreferrer"
        label={formatMessage({ id: 'navigation.privacypolicy' })}
      />
      <Button
        size="xs"
        color="primary"
        buttonStyle="ghost"
        as="a"
        href={formatMessage({ id: 'navigation.help.url' })}
        target="_blank"
        rel="noopener noreferrer"
        label={formatMessage({ id: 'navigation.help' })}
      />
    </Flex>
  );
};

export default HelpLinks;
