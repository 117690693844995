import { NextPage, NextPageContext } from 'next';
import * as Sentry from '@sentry/nextjs';
import NextErrorComponent, { ErrorProps } from 'next/error';
import ErrorLayout from '~/pages/layouts/ErrorLayout';

export type ErrorPageProps = {
  statusCode: number;
  href?: string;
  shouldForceSignOut?: boolean;
};

const ErrorPage: NextPage<ErrorPageProps> = ({ statusCode, href, shouldForceSignOut }) => (
  <ErrorLayout statusCode={statusCode} href={href} shouldForceSignOut={shouldForceSignOut} />
);

ErrorPage.getInitialProps = async (context: NextPageContext) => {
  const { res, err, asPath } = context;
  // eslint-disable-next-line no-nested-ternary
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;

  const errorInitialProps = await NextErrorComponent.getInitialProps(context as any);

  if (err) {
    if (err.message.indexOf('Connection to Indexed Database server lost. Refresh the page to try again') >= 0) {
      window.location.reload();
      return { ...errorInitialProps, statusCode } as ErrorProps;
    }

    Sentry.captureException(err);

    // Flushing before returning is necessary if deploying to Vercel, see
    // https://vercel.com/docs/platform/limits#streaming-responses
    await Sentry.flush(2000);

    return { ...errorInitialProps, statusCode } as ErrorProps;
  }

  Sentry.captureException(
    new Error(`_error.js getInitialProps missing data at path: ${asPath}`),
  );

  await Sentry.flush(2000);

  return { ...errorInitialProps, statusCode } as ErrorProps;
};

export default ErrorPage;
