import { FC } from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';
import { Paragraph } from '@emochan-cabinet/ui';

// https://www.figma.com/file/n6LFSVfh9iDDow6pMlU4Yy/%F0%9F%92%A0Cabinet-%2F-Portal?node-id=1912%3A41268
const OrDivider: FC<FlexProps> = ({ children, ...props }) => (
  <Flex
    alignItems="center"
    justifyContent="center"
    backgroundImage="linear-gradient(var(--emochan-colors-border-light-opaque), var(--emochan-colors-border-light-opaque))"
    backgroundSize="calc(100% - calc(var(--emochan-space-12) * 2)) 1px"
    backgroundRepeat="no-repeat"
    backgroundPosition="center center"
    {...props}
  >
    <Paragraph as="span" size="xs" align="center" color="grey.60" px="24" bg="grey.0">
      {children}
    </Paragraph>
  </Flex>
);

OrDivider.displayName = 'OrDivider';

export default OrDivider;
